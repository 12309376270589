.FirstBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
}

.BGVideo {
  position:fixed;
  z-index: -1;
  height: 100%;
  width:100%;
  object-fit: cover;
  -o-object-fit: cover;
}

.FirstBlock p{
  text-align: center;
  font-size: 70px;
  color: white;
  font-weight:600;
  margin:0px
}

.Button {
  background-color: transparent;
  border: 2px solid white;
  border-radius: 25px;
  padding:5px 20px;
  font-size: 25px;
  font-weight: 600;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  transition-duration: 0.4s;
  color: white;
}

.Button i {
  margin-top: 5px;
}

.Button:hover {
  background-color: dodgerblue;
  color: white;
  border: 2px solid white;
}

.Button:hover #icon {
  animation-name: rotate;
  animation-duration: 0.5s;
}

@keyframes rotate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

.Navbar {
  background: transparent;
  height: 70px;
  margin-top: -70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0px 75px;
  background-color: #fff;
  box-shadow: 0px 0px 5px white;
  transition: 0.2s ease all;
}

.NavLogo {
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
}

.NavbarRightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70px;
}

.NavbarElement {
  text-decoration: none;
  cursor: pointer;
  padding: 1rem 1rem;
  box-sizing: border-box;
  height: 70px;
}
.NavbarElement span {
  color: #222;
  font-size: 20px;
  font-weight: 600;
}

.NavbarElement:hover {
  border-bottom: 3px solid #222;
  box-sizing: border-box;
}

.NavbarElementActive {
  border-bottom: 3px solid #222;
}

*{
  box-sizing: border-box;
}

.titleBox{
  width: 100%;
  height: 120px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #002;
}

.SidebarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}

.Sidebar {
  display: none;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0px 0px 5px white;
  
}

@media screen and (max-width:790px) {
  .Navbar {
    display: none;
  }
  .Sidebar {
    display: block;
  }
}

.collapsedSideBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0px 30px;
  width: 100%;
}

.SecondBlock {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.CardsWrapper {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  /*row-gap: 30px;
  column-gap: 30px;
  padding: 20px ;
  height: 100vh;*/
  width: 100%;
}

@media screen and (max-width:790px) {
  .CardsWrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
  
}

.Card {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*border-radius: 5px;
  box-shadow:0 1px 3px rgba(255,255,255,0.2);*/
  padding: 100px;
  transition:all 0.2s ease-in-out;
  color: #fff;
  box-sizing: border-box;
}

@media screen and (max-width:790px) {
  .Card {
    padding: 40px;
  }
}

/*.Card:hover {
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-align: justify;
  text-justify: inter-word;
}*/

.CardText {
  font-size: 19px;
}

.ThirdBlock {
  width: 100%;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.CardsWrapperTB {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 50px;
  column-gap: 50px;
  padding: 100px 50px ;
  width: 100%;
  
}

@media screen and (max-width:790px) {
  .CardsWrapperTB {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding: 20px;
  }
  
}

.CardTB {
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  box-shadow:0 1px 3px rgba(255,255,255,0.2);
  padding: 60px;
  transition:all 0.2s ease-in-out;
  color: #000;
  box-sizing: border-box;
  position: relative;
}

.CardTB:hover {
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

@media screen and (max-width:790px) {
  .CardTB {
    padding: 50px;
  }
  
}

.FourthBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.Contacts {
  color:#fff;
  position: absolute;
  top: 10%;
  left:60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 22px;
}

.ContactImg {
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media screen and (max-width:790px) {
  .Contacts h1 {
    text-align: center;
  }
  .Contacts {
    position: relative;
    background-color: #059BCA;
    top:0;
    left: 0;
    padding: 40px;
  }
}

.Footer {
  background-color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 100px 120px;
  color: #fff;
}

.FooterColumn {
  display: flex;
  flex-direction: column;
  margin: 0px 50px;
}

@media screen and (max-width:790px) {
  .FooterColumn {
    margin: 0px 20px;
  }
  .Footer {
    align-items: flex-start;
    padding: 50px 50px;
  }
  .Footer i {
    margin-top: 50%;
  }
}

.FooterColumn p:nth-child(1) {
  font-weight: bold;
}

.Footer i {
  font-size: 35px;
  background-color: #fff;
  box-shadow: 0px 0px 15px grey;
  border-radius: 50%;
  color: #000;
  width: 50px;
  height:50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 80px;
}


